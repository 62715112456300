import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// import { Link } from "@c-atoms/Link"
import { FluidImage, BackgroundFluidImage } from "@c-atoms/Image"
// import { Svg } from "@c-atoms/Svg"
import NewsList from "@c-modules/NewsList"
import css from "@css/layout/index/l-news.module.styl"

export default function IndexNews()
{
    const q = useStaticQuery(graphql`
        query {
            newsData: allMarkdownRemark(
                filter: {frontmatter: {pagetype: {eq: "news"}}}
                limit: 5
                sort: { fields: [frontmatter___date], order: DESC }
            ) {
                edges {
                    node {
                        excerpt(pruneLength: 160)
                        html
                        frontmatter {
                            public
                            title
                            category
                            date(formatString: "YYYY-MM-DD")
                            link
                        }
                        fields {
                            slug
                        }
                    }
                }
            }
        }
    `)

    return(
        <section className={css.lNews} id="news">
            <div className={css.lNews__inner}>
                <h3 className={css.lNews__title}>NEWS</h3>
                <NewsList data={q.newsData.edges}/>
            </div>
        </section>
    )
}
