import React from "react"
// import InViewMonitor from "react-inview-monitor"
import GatsbyImage from "gatsby-image/withIEPolyfill"
import GatsbyBackgroundImage from "gatsby-background-image"

// import { useStores } from "@hooks/useStores"


function FluidImage({ data, ...props }) {

    return (
        <GatsbyImage
            fluid={data.childImageSharp.fluid}
            // loading='eager'//lazy, eager, auto
            {...props}
        />
    )
}

function BackgroundFluidImage({ children, data, className, ...props }) {

    return (
        <div className={className}>
            <GatsbyBackgroundImage
                fluid={data.childImageSharp.fluid}
                // loading='eager'//lazy, eager, auto
                {...props}
            >
                {children}
            </GatsbyBackgroundImage>
        </div>
    )
}

export { FluidImage, BackgroundFluidImage }