import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "@c-atoms/Link"
import { FluidImage, BackgroundFluidImage } from "@c-atoms/Image"
// import { Svg } from "@c-atoms/Svg"
import LogoSVG from "@images/IndexHero__lbl__title.svg"

const css = {
    ...require("@css/layout/index/l-visiual.module.styl"),
}

export default function IndexHero()
{
    const q = useStaticQuery(graphql`
        query {
            bg : file(relativePath: { eq: "IndexVisiual__img__bg@2x.jpg" }) { childImageSharp { fluid(maxWidth: 1440) { ...GatsbyImageSharpFluid } } }
        }
    `)

    const sns = []

    return(
        <section className={css.lVisiual} id="visiual">
            <FluidImage className={css.lVisiual__background} data={q.bg}/>
            {/* <BackgroundFluidImage className={css.lVisiual__background} data={q.bg} style={{backgroundPosition: 'top center'}}/> */}
        </section>
    )
}
