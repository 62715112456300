import React from "react"
// import { graphql, useStaticQuery } from "gatsby"
import { Link } from "@c-atoms/Link"
// import { FluidImage, BackgroundFluidImage } from "@c-atoms/Image"
// import { Svg } from "@c-atoms/Svg"
import css from "@css/project/p-news-list.module.styl"

export default function NewsList({data, moreLink})
{
    return(
        <div className={css.pNewsList}>
            <ul className={css.pNewsList__inner}>
                {data && data.map(({ node }, i) => {
                    const { html } = node
                    const { slug } = node.fields
                    const { date, category, title, link } = node.frontmatter
                    const dateSplit = date.split('-')

                    return (
                        <li className={css.pNewsList__item} key={i}>
                            <Link className={css.pNewsList__item__inner} to={link} blank>
                                <time className={css.pNewsList__date} dateTime={date}>
                                    <span className={css.y}>{dateSplit[0]}</span>
                                    <span className={css.m}>{dateSplit[1]}</span>
                                    <span className={css.d}>{dateSplit[2]}</span>
                                </time>
                                <div className={css.pNewsList__detail}>
                                    <h4 className={css.title}>{title}</h4>
                                    <div className={css.content} dangerouslySetInnerHTML={{ __html: html }} />
                                </div>
                            </Link>
                        </li>
                    )
                })}
            </ul>
            { moreLink &&
                <div className={css.pNewsList__footer}>
                    <Link className={css.pNewsList__more} to={moreLink}>MORE</Link>
                </div>
            }
        </div>
    )
}
