import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "@c-atoms/Link"
import { FluidImage, BackgroundFluidImage } from "@c-atoms/Image"
import { Button } from "@c-atoms/Button"
// import Mailto from '@components/Mailto'
const css = {
    ...require("@css/layout/index/l-contact.module.styl"),
}

export default function Contact()
{
    return(
        <section className={css.lContact} id="contact">
            <div className={css.lContact__inner}>
                <h3 className={css.lContact__title}>
                    CONTACT
                </h3>
                <p className={css.lContact__text}>
                    お仕事のご依頼等は、お問合せフォームにて受け付けております。
                </p>
                <div className={css.lContact__footer}>
                    <Button className={css.lContact__button} to="/contact/">お問い合わせフォーム</Button>
                </div>
            </div>
        </section>
    )
}
