import React from "react"
// import { graphql , useStaticQuery} from "gatsby"
// import { useObserver } from "mobx-react-lite"
// import { useStores } from '@hooks/useStores'
import Seo from "@c-modules/Seo"
import Hero from "@c-blocks/index/Hero"
import News from "@c-blocks/index/News"
import Visiual from "@c-blocks/index/Visiual"
import Contact from "@c-blocks/index/Contact"

// import css from "@css/components/blocks/index/Index.module.styl"

export default function Index({data})
{
	return(
		<>
		<Seo/>
		<main>
			<Hero/>
			<News/>
			<Visiual/>
			<Contact/>
		</main>
		</>
	)
}

// export const pageQuery = graphql`
// 	query {
// 		newsData: allMarkdownRemark(
// 			filter: {frontmatter: {pagetype: {eq: "news"}}}
// 			limit: 5
// 			sort: { fields: [frontmatter___date], order: DESC }
// 		) {
// 			edges {
// 				node {
// 					excerpt(pruneLength: 160)
// 					html
// 					frontmatter {
// 						public
// 						title
// 						category
// 						date(formatString: "YYYY.MM.DD")
// 					}
// 					fields {
// 						slug
// 					}
// 				}
// 			}
// 		}
// 	}
// `