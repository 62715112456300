import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "@c-atoms/Link"
import { FluidImage, BackgroundFluidImage } from "@c-atoms/Image"
// import { Svg } from "@c-atoms/Svg"
// import LogoSVG from "@images/IndexHero__lbl__title.svg"

const css = {
    ...require("@css/layout/index/l-hero.module.styl"),
    ...require("@css/project/p-hero-content.module.styl"),
    ...require("@css/project/p-hero-background.module.styl"),
}

export default function IndexHero()
{
    const q = useStaticQuery(graphql`
        query {
            bg : file(relativePath: { eq: "IndexHero__img__bg@2x.jpg" }) { childImageSharp { fluid(maxWidth: 1440) { ...GatsbyImageSharpFluid } } }
            logo : file(relativePath: { eq: "IndexHero__lbl__title.svg" }) { publicURL }
            logo2 : file(relativePath: { eq: "IndexHero__lbl__title.png" }) { childImageSharp { fluid(maxWidth: 960) { ...GatsbyImageSharpFluid } } }
        }
    `)

    const sns = []

    return(
        <section className={css.lHero} id="hero">
            <BackgroundFluidImage className={css.pHeroBackground} data={q.bg} style={{backgroundPosition: 'center right'}}/>
            <div className={css.lHero__inner}>
                <div className={css.pHeroContent}>
                    <div className={css.pHeroContent__inner}>
                        <h1 className={css.pHeroContent__title}>
                            {/* <LogoSVG /> */}
                            {/* <img src={q.logo.publicURL} alt="RESTART MARINA"/> */}
                            <FluidImage data={q.logo2} alt="MARINA BIRTHDAY LIVE2021 -ケーキの上からしつれいします-"/>
                        </h1>
                        <h2 className={css.pHeroContent__text}>
                            <span>青森県八戸出身</span>
                            <span>アイドル</span><br/>
                            <span>"MARINA"の</span>
                            <span>オフィシャル</span>
                            <span>Webサイト</span>
                        </h2>
                    </div>
                </div>
            </div>
        </section>
    )
}
